import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./styles/reset.css";
import "font-awesome/css/font-awesome.css";
import './font/iconfont/iconfont.css';
import "./font/iconfont/iconfont.js";

import animated from 'animate.css'
Vue.use(animated)

Vue.config.productionTip = false;

import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad, {
  loading: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
});

Vue.prototype.$bus = new Vue();
import axios from 'axios'
Vue.prototype.$getch = function (
    $url,
    $method,
    $data,
    $headers,
  ) {
    return new Promise((resolve, reject) => {
      axios({
        url: $url,
        method: $method.toLowerCase(),
        data: $data,
        headers: $headers,
        timeout: 30000
      }).then(res => {
        resolve(res.data);
      }).catch((err) => {
        reject(err);
      })
    });
  },

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");

export default Vue;