import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // 全局状态
  state: {},
  // 修改全局状态
  mutations: {

  },
  getters: {

  },
  actions: {},
  modules: {}
})