const Home = () => import('layout/home.vue')

const main = "content";
const routes = [{
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    components: {
      [main]: Home
    },
  }
]
export default routes